import React from 'react';
import styled from 'styled-components';
// import why from '../assets/Images/whychoose.jpg';
import { mobile } from '../responsive';

function Choose() {
  return (
    <Wrapper>
      <Header>
        Why Choose Us ?
      </Header>
      <Why>
        <ImageContainer>
          <img src='/assets/Images/whychoose.jpg' style={{ width: "100%", height: "100%" }} alt="Why Choose Us" />
        </ImageContainer>
        <InfoContainer>
          <InfoItem><span>Creative Design</span></InfoItem>
          <InfoItem><span>Fully Responsive Website</span></InfoItem>
          <InfoItem><span>Development and Testing</span></InfoItem>
          <InfoItem><span>Latest Technologies</span></InfoItem>
          <InfoItem><span>Performance Marketing</span></InfoItem>
          <InfoItem><span>Complete Transparency</span></InfoItem>
        </InfoContainer>
      </Why>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 75%;
  margin: 70px auto;
  display: flex;
  flex-direction: column;
  ${mobile({ padding: '0 20px', margin: '70px auto' })}; 
`;

const Header = styled.h1`
  text-align: center;
  flex: 1;
`;

const Why = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px; 

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 20px;
  
  @media (min-width: 768px) {
    padding: 80px; 
    grid-template-columns: repeat(2, 1fr);
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #1992cb;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  height: 50px;

  span {
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
  }
`;

export default Choose;
