import React from 'react';
import styled from 'styled-components';
// import banner from '../../public/assets/Images/banner.jpg'
import { mobile } from '../responsive.js'; 

function Banner() {
  return (
    <Wrapper>
      <BannerImage src='/assets/Images/banner.jpg' alt="banner" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px;

  ${mobile({
    height: '170px',
    width: '100%', 
    marginTop: '70px',
    marginBottom: '0px',
    zIndex:"8",
  })}
`;

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;

  ${mobile({
    width: '100%',
    height: 'auto',
    zIndex:'4'
  })}
`;

export default Banner;