import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './testimonials.css';

import { Autoplay, Pagination } from 'swiper/modules';
function Testimonials() {
  const sliderItems = [
    { id: 1, content: 'I highly recommend TechIt Digital for their website designing and digital marketing services. They have a talented team that is dedicated to delivering top-quality work and helping their clients succeed. ' },
    { id: 2, content: 'I highly recommend TechIt Digital for their website designing and digital marketing services. They have a talented team that is dedicated to delivering top-quality work and helping their clients succeed. ' },
    { id: 3, content: 'I highly recommend TechIt Digital for their website designing and digital marketing services. They have a talented team that is dedicated to delivering top-quality work and helping their clients succeed. ' },
    { id: 4, content: 'I highly recommend TechIt Digital for their website designing and digital marketing services. They have a talented team that is dedicated to delivering top-quality work and helping their clients succeed. ' },
    { id: 5, content: 'I highly recommend TechIt Digital for their website designing and digital marketing services. They have a talented team that is dedicated to delivering top-quality work and helping their clients succeed. ' },
  ];


  return (
    <Wrapper>
      <Heading>
        <h1>Our <span style={{ color: "#1992cb" }}>Clients</span> Say</h1>
      </Heading>
      <Swiper
        slidesPerView={3}
        spaceBetween={80}
        centeredSlides={true}
        loop={true}
        autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          200: {
            slidesPerView: 1, 
            spaceBetween: 20,
            navigation: false,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: false,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
            navigation: false,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {sliderItems.map((item) => (
        <SwiperSlide>
          <ReviewCard>
          <InfoContainer>
        <p style={{ color: "#fff" }}>{item.content}</p>
      </InfoContainer>
      <EditorContainer>
        <h3 style={{ color: "#fff" }}>Piyush Mittal</h3>
      </EditorContainer>
          </ReviewCard>

        </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  background-color: #f9f9f9;
`;

const Heading = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const InfoContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  line-height:1.7;
`;

const EditorContainer = styled.div`
  padding-top: 10px;
  text-align: center;
`;

const ReviewCard = styled.div`
width:100%;
height:100%;
border-radius : 4px;
background-color:#1992cb;`

export default Testimonials;
