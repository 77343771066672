import React from 'react';
import styled from 'styled-components';
import './service.css';
import { mobile } from '../../responsive';
function Card({ heading, para, image }) {
  return (
    <CardContainer>
      <Image>
        <img style={{ objectFit: 'cover', width: '100%', height: '200px' }} src={image} alt="" />
      </Image>
      <CardHeading>
        <h2 style={{ color: "#1992cb" }}>{heading}</h2>
      </CardHeading>
      <Para>
        {para}
      </Para>
      <HeadButton>
        <Button>{heading}</Button>
      </HeadButton>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  background: #fff;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 350px;
  width: 100%;
  height: auto;
  margin: auto;
  box-sizing: border-box;
      box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.48);
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.48);
  justify-content: space-between;
`;

const Image = styled.div`
  height: 200px;
  width: 100%;
`;

const CardHeading = styled.div`
  text-align: center;
  width: 100%;
  margin: 10px 0;
`;

const Para = styled.div`
  text-align: center;
  padding: 0 12px;
  font-family: sans-serif;
  line-height: 1.5;
  // background-color:red;
  height:120px;
  // flex: 1; 
  ${mobile({height:"140px"})}
`;

const HeadButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 13px 20px;
  background-color: #1992cb;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
`;

export default Card;
